$(document).on('DOMContentLoaded', function() {
  const fileInput = document.getElementById("tutorial-file-upload");

  if (fileInput) {
    fileInput.addEventListener('change', function(event) {
      const file = event.target.files[0];

      if (file) {
          const reader = new FileReader();

          reader.onload = function(e) {
            const data = JSON.parse(e.target.result);
            // console.log("data", data);

            // Populate form fields with the parsed JSON data
            document.getElementById("tutorial_name").value = data.tutorial.name || '';
            document.getElementById("tutorial_description").value = data.tutorial.description || '';

            // Handling select fields for subjects, tiers and field_of_studies
            if (data.tutorial.subject_id) {
              document.getElementById("tutorial_subject_id").value = data.tutorial.subject_id;
            }

            if (data.tutorial.tier_id) {
              document.getElementById("tutorial_tier_id").value = data.tutorial.tier_id;
            }

            if (data.tutorial.field_of_study_id) {
              document.getElementById("tutorial_field_of_study_id").value = data.tutorial.field_of_study_id;
            }

          };

          reader.readAsText(file);
      }
    });
  }
});
